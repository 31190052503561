const App = {
    SetBackground: () => {
        $('[setBackground]').each(function() {
            var background = $(this).attr('setBackground')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-size": "cover",
                "background-position": "center center"
            })
        })
        $('[setBackgroundRepeat]').each(function() {
            var background = $(this).attr('setBackgroundRepeat')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-repeat": "repeat"
            })
        })
    },
    EqualHeightElement: el => {
        let height = 0;
        let thisHeight = 0;
        $(el).each(function() {
            thisHeight = $(this).height();
            if (thisHeight > height) {
                height = thisHeight;
            }
        });
        $(el).height(height)
    },
    InitLazyLoad: () => {
        return new LazyLoad({
            elements_selector: ".lazy"
        });
    }
}
const Header = {
    LanguageWrapper: () => {
        $('.language-wrapper .language-active').on('click', function(e) {
            $(this).parents('.language-wrapper').toggleClass('active')
            $('html').one('click', function() {
                $('.language-wrapper').removeClass('active')
            });
            e.stopPropagation();
        })
    },
    ToggleMenuMobile: () => {
        // $('header .toggle-button').on('click', function() {
        //     $(this).toggleClass('active')
        //     $('header .list-page-top').toggleClass('active')
        //     $('header .mobile-wrapper').toggleClass('active')
        //     $('header .navigation-zone-child-wrapper').removeClass('active')
        //     $('header .backdrop-wrapper').fadeToggle(400)
        // })
    },
    SearchBoxMobile: () => {
        // $('header .search-icon').on('click', function() {
        //     $('header .search-wrapper').slideToggle()
        //     $('header').toggleClass('show-search-wrapper')
        // })
    },
    MobileMapping: () => {
        return {
            "MainListWrapper": new MappingListener({
                selector: 'header .main-list-wrapper',
                mobileWrapper: 'header .sub-list-wrapper',
                mobileMethod: 'insertBefore',
                desktopWrapper: 'header .logo-wrapper',
                desktopMethod: 'insertAfter',
                breakpoint: 1025,
            }).watch(),
            "Language": new MappingListener({
                selector: 'header .language-wrapper',
                mobileWrapper: 'header .side-menu-wrapper',
                mobileMethod: 'appendTo',
                desktopWrapper: 'header .open-side-menu',
                desktopMethod: 'insertAfter',
                breakpoint: 1200,
            }).watch(),
            "BookButton": new MappingListener({
                selector: 'header .book-button-wrapper',
                mobileWrapper: 'header .side-menu-wrapper',
                mobileMethod: 'appendTo',
                desktopWrapper: 'header .language-wrapper',
                desktopMethod: 'insertAfter',
                breakpoint: 1200,
            }).watch(),

        }

    }
}

function HeaderOnScroll() {
    $(window).scrollTop() > 50 ? $('header').addClass('active') : $('header').removeClass('active')
}

function DuyenHaSlider() {
    let HomeBanner = new Swiper('.home-banner .swiper-container', {
        speed: 1000,
    })
    let HomeActivities = new Swiper('.activities-slider .swiper-container', {
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.activities-slider .swiper-next',
            prevEl: '.activities-slider .swiper-prev'
        }
    })
    let HomeAway = new Swiper('.HomeAwayFromHomeDetail-1 .swiper-container', {
        speed: 1000,
        navigation: {
            nextEl: '.HomeAwayFromHomeDetail-1 .swiper-next',
            prevEl: '.HomeAwayFromHomeDetail-1 .swiper-prev'
        }
    })
    let HomeAwayOffers = new Swiper('.HomeAwayFromHomeDetail-2 .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        navigation: {
            nextEl: '.HomeAwayFromHomeDetail-2 .swiper-next',
            prevEl: '.HomeAwayFromHomeDetail-2 .swiper-prev'
        },
        breakpoints: {
            1025: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 1
            }
        }
    })
    let GoodFood = new Swiper('.GoodFoodDetail-1 .swiper-container', {
        speed: 1000,
        navigation: {
            nextEl: '.GoodFoodDetail-1 .swiper-next',
            prevEl: '.GoodFoodDetail-1 .swiper-prev'
        }
    })
    let Cuisine = new Swiper('.GoodFoodDetail-2 .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        navigation: {
            nextEl: '.GoodFoodDetail-2 .swiper-next',
            prevEl: '.GoodFoodDetail-2 .swiper-prev'
        },
        breakpoints: {
            1025: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 1
            }
        }
    })

}

function ToggleHeader() {
    $('header .side-menu-wrapper .sub-list-wrapper ul li em').on('click', function() {
        $(this).parent().toggleClass('active')
        $(this).siblings('ul').slideToggle()
    })
}

function PositionActivitiesNavigation() {
    let hei = $('.activities-slider .swiper-slide.swiper-slide-active .image img').outerHeight()
    setTimeout(() => {
        $('.activities-slider .swiper-navigation>div').css({
            'top': hei / 2 + 'px'
        })
    }, 100);

}

function BackToTop() {
    $('#backToTop').on('click', function() {
        $('html, body').animate({ scrollTop: 0 }, 800)
    })
}

function ToggleSideMenu() {
    // Open SideMenu
    $('header .open-side-menu a').on('click', function() {
            $('header .backdrop-wrapper').addClass('active')
            $('header .side-menu-wrapper').addClass('active')
            $('body').addClass('side-menu')
            $('html').addClass('side-menu')
        })
        // Close SideMenu
    $('header .side-menu-wrapper .close-button').on('click', function() {
        $(this).parent().removeClass('active')
        $('header .backdrop-wrapper').removeClass('active')
        $('body').removeClass('side-menu')
        $('html').removeClass('side-menu')
    })
    $('header .backdrop-wrapper').on('click', function() {
        $(this).removeClass('active')
        $('header .side-menu-wrapper').removeClass('active')
        $('body').removeClass('side-menu')
        $('html').removeClass('side-menu')
    })
}

function DuyenHaAccordion() {
    $('.inclusion-wrapper .heading').on('click', function() {
        $(this).siblings().slideToggle()
        $(this).toggleClass('active')
    })
}

$(document).ready(function() {
    $('[data-fancybox]').fancybox({});
    App.SetBackground()
    App.InitLazyLoad()
    Header.LanguageWrapper()
    Header.ToggleMenuMobile()
    Header.MobileMapping()
    Header.SearchBoxMobile()
    DuyenHaSlider()
    DuyenHaAccordion()
    PositionActivitiesNavigation()
    ToggleHeader()
    ToggleSideMenu()
    BackToTop()
    HeaderOnScroll()
    setTimeout(() => {
        App.EqualHeightElement('.GoodFoodDetail-2 .room-item figure figcaption p')
        App.EqualHeightElement('.room-item figure .image img')
        App.EqualHeightElement('.HomeAwayFromHomeDetail-2 .room-item figure figcaption p')
        App.EqualHeightElement('.ExclusiveOffer-1 .room-item figure figcaption .brief')
        App.EqualHeightElement('.WereProud-2 .item')
    }, 100);
    $('#ctl00_mainContent_ctl02_ctl00_divPager').appendTo($('.HomeAwayFromHome-1 .container'))
        // $('footer .wrap-form>div .qparagraph').appendTo($('footer .right-wrapper'))
})
$(window).on('scroll', function() {
    HeaderOnScroll()
})